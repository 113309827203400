export const { MULTI_SENDER_ADDRESS } = process.env;
export const { MULTI_SENDER_FEE_COLLECTOR } = process.env;
export const { TOKEN_MANAGER_FEE_COLLECTOR } = process.env;
export const { MULTI_SENDER_FEE_SOL } = process.env;
export const { MULTI_SENDER_FEE_TOKEN } = process.env;
export const { BURNER_FEE } = process.env;
export const { MINT_FEE } = process.env;
export const { CUSTOM_MINT_FEE } = process.env;
export const { TOKENS_UPDATE_FEE } = process.env;
export const { SNAPSHOT_FEE } = process.env;
export const { MODE } = process.env;
export const { JITO_TIP_1X } = process.env;
export const { JITO_TIP_5X } = process.env;
export const { JITO_TIP_10X } = process.env;
export const { TX_AMT_L } = process.env;
export const { TX_AMT_M } = process.env;
export const { TX_AMT_H } = process.env;
export const { FACTOR } = process.env;
export const { BASE_PDA } = process.env;
export const { PUMPFUN_CHARGE } = process.env;
export const { BONDING_CHARGE } = process.env;
export const { HYPERPAD_CHARGE } = process.env;
